import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import AuthContext from "../context/AuthContext";
import {useHistory} from "react-router-dom";
import {enqueueSnackbar, useSnackbar} from "notistack";
import errorSound from "../assets/error_sound.mp3"

function useCallDataApi(model) {
    const history = useHistory()
    const {headers, formDataHeaders} = useAuthHeader();
    const {logoutUser} = useContext(AuthContext)
    const {baseUrl, sounds} = useContext(ConfigContext);
    const {enqueueSnackbar} = useSnackbar();
    // const {setSubscriptionError, setPlanError, setUnexpectedError, setNetworkError, setPermissionError} = useContext(ErrorContext)

    const url = baseUrl + '/' + model + '/';

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })

    // const errorMap = {
    //     '1': () => setSubscriptionError(true),
    //     '2': () => setPlanError(true),
    //     '3': () => setPermissionError(true),
    //     undefined: () => setUnexpectedError(true),
    // }

    const handleError = (e) => {
        console.log(e)
        if (e?.code === 'ERR_NETWORK') enqueueSnackbar('Network error!', {variant: 'error'})
        if (e.response.status === 401) logoutUser()
        else {
            if (sounds) {
                const audio = new Audio(errorSound)
                audio.play()
            }

            enqueueSnackbar('Váratlan hiba!', {variant: 'error'})
        }

        // errorMap[e.response?.data?.detail]()
    }

    const createData = useCallback((data) => {
            return authenticatedAPI.post("", JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const postData = useCallback((slug, data) => {
            return authenticatedAPI.post(slug, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const getData = useCallback((id = null) => {
            return authenticatedAPI.get(id)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const updateData = useCallback((id, data) => {
            return authenticatedAPI.patch(`${id}`, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))

        },
        [authenticatedAPI]
    );

    const deleteData = useCallback((id) => {
            return authenticatedAPI.delete(`${id}/`)
                .then(r => r)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return formDataAPI.post("", data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(`${id}/`, data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    return {
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        formDataCreateData,
        formDataUpdateData
    };
}

export default useCallDataApi;