import * as React from 'react';
import {alpha, styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CelebrationIcon from '@mui/icons-material/Celebration';
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import {Link, useHistory} from "react-router-dom";
import PercentIcon from '@mui/icons-material/Percent';
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import SettingsIcon from '@mui/icons-material/Settings';
import BedIcon from '@mui/icons-material/Bed';
import AbcIcon from '@mui/icons-material/Abc';
import ImageIcon from '@mui/icons-material/Image';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const drawerWidth = 240;
const isDesktop = window.outerWidth > 800;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let {permissions, username, logoutUser} = useContext(AuthContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history = useHistory();

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
            <MenuItem onClick={() => {history("/settings")}}>
                <IconButton>
                    <SettingsIcon/>
                </IconButton>
                <p>Beállítások</p>
            </MenuItem>
            <MenuItem onClick={logoutUser}><IconButton><LogoutIcon/></IconButton><p>Kijelentkezés</p></MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = () => (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem onClick={() => {history("/settings")}}>
                <IconButton
                    size="large"
                    aria-haspopup="true"
                    color="inherit">
                    <SettingsIcon/>
                </IconButton>
                <p>Beállítások</p>
            </MenuItem>
            <MenuItem onClick={logoutUser}>

                <IconButton
                    size="large"
                    aria-haspopup="true"
                    color="inherit">
                    <LogoutIcon/>
                </IconButton>
                <p>Kijelentkezés</p>
            </MenuItem>
        </Menu>
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            {renderMenu()}
            {renderMobileMenu()}
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
                        {props.title}
                    </Typography>
                    {/*    <Search>*/}
                    {/*    <SearchIconWrapper>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </SearchIconWrapper>*/}
                    {/*    <StyledInputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</Search>*/}
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Typography variant="p" style={{
                            display: "flex",
                            alignItems: "center"
                        }}>Bejelentkezve: {username}</Typography>
                        {/*<IconButton size="large" aria-label="show 4 new mails" color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="error">*/}
                        {/*        <MailIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    aria-label="show 17 new notifications"*/}
                        {/*    color="inherit"*/}
                        {/*>*/}
                        {/*    <Badge badgeContent={17} color="error">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {(!isDesktop && !open) ? <></> :
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        <Link className="menu-item" to="/reservations">
                            <ListItem>
                                <ListItemIcon>
                                    <PeopleAltIcon/>
                                </ListItemIcon>
                                <ListItemText>Foglalások</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/events">
                            <ListItem>
                                <ListItemIcon>
                                    <CelebrationIcon/>
                                </ListItemIcon>
                                <ListItemText>Események</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/rooms">
                            <ListItem>
                                <ListItemIcon>
                                    <BedIcon/>
                                </ListItemIcon>
                                <ListItemText>Szobák</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/coupon-codes">
                            <ListItem>
                                <ListItemIcon>
                                    <PercentIcon/>
                                </ListItemIcon>
                                <ListItemText>Kuponkódok</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/gallery-images">
                            <ListItem>
                                <ListItemIcon>
                                    <ImageIcon/>
                                </ListItemIcon>
                                <ListItemText>Galéria képek</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/main-text">
                            <ListItem>
                                <ListItemIcon>
                                    <AbcIcon/>
                                </ListItemIcon>
                                <ListItemText>Főoldali szöveg</ListItemText>
                            </ListItem>
                        </Link>
                        <Link className="menu-item" to="/email-texts">
                            <ListItem>
                                <ListItemIcon>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText>Email szövegek</ListItemText>
                            </ListItem>
                        </Link>
                        {/*<Divider/>*/}
                    </List>
                </Drawer>
            }
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader/>
                {props.children}
            </Box>
        </Box>
    );
}