import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import logoutSound from "../assets/logout_sound.mp3"
import loginSound from "../assets/login_sound.mp3"


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl, sounds} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null);
    const [permissions, setPermissions] = useState(() => localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const history = useHistory();

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        console.log(u)
        setPermissions(u.permissions);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", u?.permissions);
    }

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${baseUrl}/api/token/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({username: e.target.username.value, password: e.target.password.value}),
            });
            const data = await response.json();
            if (response.status === 200) {
                setAuthStorage(data)
                history.push("/");
                if (sounds) {
                    const audio = new Audio(loginSound)
                    audio.play()
                }
            } else {
                alert("Hiba, helytelen email vagy jelszó!");
            }
        } catch (e) {
            console.log(e)
            alert("Hiba, a bejelentkezés során, próbálja újra később!");
        }

    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        setPermissions(null);
        localStorage.removeItem("authTokens");
        localStorage.removeItem("permissions");
        if (sounds) {
            const audio = new Audio(logoutSound);
            audio.play()
        }

    }, []);

    const updateToken = useCallback(async () => {
        const response = await fetch(`${baseUrl}/api/token/refresh/`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({refresh: authTokens?.refresh}),
        });

        const data = await response.json();
        if (response.status === 200) setAuthStorage(data)
        else logoutUser()

    }, [authTokens?.refresh, logoutUser]);

    const forgotPassword = async (e) => {
        e.preventDefault();
        const response = await fetch(`${baseUrl}/password/send_link/`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({email: e.target.email.value}),
        });
        if (response.status === 200) history.push("/")
        else alert("Ajjaj, valami nem sikerült!")
    };

    const newPassword = async (e) => {
        e.preventDefault();
        const response = await fetch(`${baseUrl}/password/change_password/`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({
                password: e.target.password.value,
                password_again: e.target.password_again.value,
                slug: e.target.slug.value,
            }),
        });
        if (response.status === 200) history.push("/login")
        else alert("Ajjaj, valami nem sikerült!")
    };

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        permissions: permissions,
        forgotPassword: forgotPassword,
        newPassword: newPassword,
    };

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4;

        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, updateToken]);

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};