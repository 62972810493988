import {
    Autocomplete,
    Box,
    InputAdornment,
    NativeSelect,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useState, useContext} from "react";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/en-GB'
import {DatePicker} from "@mui/x-date-pickers";
import countries from "../../utils/countries";
import ApartmentContext from "../../context/ApartmentContext";


export default function ReservationData({reference, obj}) {

    const parseData = (dataString) => {
        try {
            const jsonString = dataString.replace(/'/g, '"')
            return JSON.parse(jsonString)
        } catch (error) {
            console.error("Error parsing data:", error)
            return null
        }
    }
    const [startDate, setStartDate] = useState(obj?.start_date || new Date())
    const [endDate, setEndDate] = useState(obj?.end_date || new Date())
    const [apartment, setApartment] = useState(obj?.apartment || 1)
    const [country, setCountry] = useState(obj ? parseData(obj?.country) : {code:"HU",label:"Magyarország",phone:"36"})
    const {apartments} = useContext(ApartmentContext)


    return <>
        <form ref={reference}>
            <Stack spacing={1}>
                <Typography variant='caption'>Ház*</Typography>
                <NativeSelect
                    inputProps={{name: 'apartment', id: 'controlled-apartment',}}
                    onChange={event => setApartment(event.target.value)}
                    value={apartment}
                >
                    {apartments.map((a, i) => <option key={`apartment_${i}`} value={a?.id}>{a?.name}</option>)}
                </NativeSelect>
                <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{width: '50%'}}>
                        <Typography variant='caption'>Vezetéknév*</Typography>
                        <TextField variant='standard' id="last_name" name="last_name"
                                   defaultValue={obj?.last_name || ''}/>
                    </Stack>
                    <Stack spacing={1} sx={{width: '50%'}}>
                        <Typography variant='caption'>Keresztnév*</Typography>
                        <TextField variant='standard' id="first_name" name="first_name"
                                   defaultValue={obj?.first_name || ''}/>
                    </Stack>
                </Stack>

                <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{width: '50%'}}>
                        <Typography variant='caption'>e-mail*</Typography>
                        <TextField variant='standard' type='email' id="email" name="email" defaultValue={obj?.email || ''}/>
                    </Stack>
                    <Stack spacing={1} sx={{width: '50%'}}>
                        <Typography variant='caption'>Telefonszám</Typography>
                        <TextField variant='standard' id="phone" name="phone" defaultValue={obj?.phone || ''}/>
                    </Stack>
                </Stack>

                <Typography variant='caption'>Vendégek száma*</Typography>
                <TextField variant='standard' type='number' id="number_of_guests" name="number_of_guests"
                           defaultValue={obj?.number_of_guests || ''}/>

                <Typography variant='caption'>Ár</Typography>
                <TextField helperText={!obj && 'Ha nem ad meg árat a rendszer számol a megadott módon'} variant='standard' type='number' id="price" name="price" defaultValue={obj?.price || ''} InputProps={{
                    endAdornment: <InputAdornment position="end">Ft</InputAdornment>
                }}/>


                <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{width: '20%'}}>
                        <input type="hidden" value={JSON.stringify(country)} name='country'/>
                        <Typography variant='caption'>Ország*</Typography>
                        <Autocomplete
                            id="country-select-demo"
                            sx={{width: '100%'}}
                            options={countries}
                            autoHighlight
                            value={country}
                            onChange={(event, newValue) => {
                                setCountry(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.label} ({option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='standard'
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={1} sx={{width: '40%'}}>
                        <Typography variant='caption'>Megye*</Typography>
                        <TextField variant='standard' id="state" name="state" defaultValue={obj?.state || ''}/>
                    </Stack>
                    <Stack spacing={1} sx={{width: '40%'}}>
                        <Typography variant='caption'>Város*</Typography>
                        <TextField variant='standard' id="city" name="city" defaultValue={obj?.city || ''}/>
                    </Stack>
                </Stack>

                <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{width: '20%'}}>
                        <Typography variant='caption'>Irányítószám*</Typography>
                        <TextField variant='standard' id="postal_code" name="postal_code"
                                   defaultValue={obj?.postal_code || ''}/>
                    </Stack>
                    <Stack spacing={1} sx={{width: '80%'}}>
                        <Typography variant='caption'>Cím*</Typography>
                        <TextField variant='standard' id="address" name="address" defaultValue={obj?.address || ''}/>
                    </Stack>
                </Stack>


                <Typography variant='caption'>Kezdő dátum*</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                    <DatePicker
                        value={startDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField variant='standard' id="start_date"
                                                            name="start_date" {...params} />}
                    />
                </LocalizationProvider>

                <Typography variant='caption'>Vég dátum*</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                    <DatePicker
                        value={endDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField variant='standard' id="end_date"
                                                            name="end_date" {...params} />}
                    />
                </LocalizationProvider>

            </Stack>
        </form>
    </>;
}