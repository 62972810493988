import {Button, FormControl, MenuItem, NativeSelect, Select, Stack, TextField, Typography} from "@mui/material";
import {useState, useContext, useEffect} from "react";
import ConfigContext from "../../context/ConfigContext";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import ApartmentContext from "../../context/ApartmentContext";


const ImageGalleryData = ({reference, obj}) => {
    const {baseUrl} = useContext(ConfigContext)
    const {apartments} = useContext(ApartmentContext)
    const [image, setImage] = useState(obj?.image || '')
    const [apartment, setApartment] = useState(obj?.apartment || 1)

    const getPic = () => {
        if (image instanceof File) return URL.createObjectURL(image)
        if (image?.startsWith('http')) return image
        if (image) return baseUrl + image;
        else return ''
    }

    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Ház</Typography>
                <FormControl fullWidth>
                    <Select
                        id="apartment-select"
                        variant='standard'
                        name='apartment'
                        value={apartment}
                        onChange={e => setApartment(e.target.value)}
                    >
                        {apartments?.map(a => <MenuItem key={a?.id} value={a?.id}>{a?.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <Typography variant='caption'>Hanyadik?</Typography>
                <TextField name='order' variant='standard' defaultValue={obj?.order || 1}/>
                <Typography variant='caption'>Kép</Typography>
                <img src={getPic(image)} style={{width: '30%', borderRadius: 10}}/>
                <Button sx={{padding: '16px'}} component='label' variant='outlined'
                        endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése
                    <input type="file"
                           onChange={event => setImage(event.target.files[0])}
                           hidden
                           name='image'
                           id='image'
                           accept='image/*'/>
                </Button>
            </Stack>

        </form>
    </>;
}

export default ImageGalleryData