import {Stack, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/hu'
import {DatePicker} from "@mui/x-date-pickers";
import {useState} from "react";


const CouponCodeData = ({reference, obj}) => {
    const [valid, setValid] = useState(new Date())
    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Kód</Typography>
                <TextField variant='standard' id="code" name="code" defaultValue={obj?.code || ''}/>
                <Typography variant='caption'>Százalék</Typography>
                <TextField variant='standard' id="percent" name="percent" defaultValue={obj?.percent || ''}/>
                <Typography variant='caption'>Kedvezmény</Typography>
                <TextField variant='standard' id="minus" name="minus" defaultValue={obj?.minus || ''}/>
                <Typography variant='caption'>Érvényesség</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                    <DatePicker
                        label="Vég dátum"
                        value={valid}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setValid(newValue);
                        }}
                        renderInput={(params) => <TextField variant='standard' id="valid_thru" name="valid_thru" {...params} />}
                     />
                </LocalizationProvider>
            </Stack>

        </form>
    </>;
}

export default CouponCodeData