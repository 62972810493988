import React, {useRef, useState} from "react";
import ReservationData from "./ReservationData";
import CheckIcon from "@mui/icons-material/Check";
import {useSnackbar} from "notistack";
import thousandSeparator from "../../utils/numberUtils";
import ListView from "../../components/Views/ListViewPage";
import useCallDataApi from "../../hooks/data";
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import {Stack} from "@mui/material";

const Reservations = () => {
    const {postData} = useCallDataApi('admin-reservations');
    // const {getData} = useCallDataApi('rooms');
    // const [acceptReservationOpen, setAcceptReservationOpen] = useState(false)
    // const [rooms, setRooms] = useState([])
    const [current, setCurrent] = useState(1)
    const reservationForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar();
    // const {apartmentId} = useContext(ConfigContext)

    const parseData = (dataString) => {
        try {
            const jsonString = dataString.replace(/'/g, '"')
            return JSON.parse(jsonString)
        } catch (error) {
            console.error("Error parsing data:", error)
            return null
        }
    }
    const columns = [
        {field: "name", headerName: "Név", width: 200},
        {field: "apartment_name", headerName: "Ház", width: 150},
        {
            field: "country", headerName: "Ország", width: 150, renderCell: (params) => {
                const country = parseData(params.row?.country)
                return <Stack spacing={1} direction='row'>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    <div>{country.label}</div>
                </Stack>
            }
        },
        // renderCell: (params) => getRoom(params.row['room'])?.name},
        {field: "state", headerName: "Megye", width: 200},
        {field: "city", headerName: "Város", width: 200},
        {field: "postal_code", headerName: "Irányítószám", width: 200},
        {field: "address", headerName: "Cím", width: 200},
        {field: "email", headerName: "Email", width: 200},
        {field: "start_date", headerName: "Kezdő dátum", width: 110},
        {field: "end_date", headerName: "Vég dátum", width: 110},
        {field: "phone", headerName: "Telefonszám", width: 110},
        // {field: "address", headerName: "Cím", width: 110},
        {
            field: "price",
            headerName: "Ár",
            width: 110,
            renderCell: (params) => thousandSeparator(params.row['price']) + ' Ft'
        },
        {field: "created_at", headerName: "Lefoglalva ekkor", width: 200},
        {field: "status", headerName: "Státusz", width: 200},
        {field: "invoice_id", headerName: "Billingo számla azonosító", width: 200},
        // {field: "accepted", headerName: "Elfogadva", width: 110, renderCell: (params) => params.row['accepted'] ? <CheckIcon/> : <CloseIcon/>},
        // {field: "paid", headerName: "Fieztés ok", width: 110, renderCell: (params) => params.row['paid'] ? <CheckIcon/> : <CloseIcon/>}
    ]

    const acceptReservation = async (id) => {
        return await postData(`${id}/accept_reservation/`, {})
            // .then(() => enqueueSnackbar('Elfogadva!', {variant: 'success'}))
            // .catch(() => enqueueSnackbar('Hiba!', {variant: 'error'}))
    }

    const sendPaymentLink = (id) => {
        postData(`${id}/send_payment_link/`, {})
            .then(() => enqueueSnackbar('Kiküldve!', {variant: 'success'}))
            .catch(() => enqueueSnackbar('Hiba!', {variant: 'error'}))
    }

    const download = (url, filename) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            })
            .catch(console.error);
    }

    const downloadInvoice = (id) => {
        postData(`${id}/download_invoice/`, {})
            .then(r => window.open(r.url, '_blank'))
            .catch(() => enqueueSnackbar('Hiba!', {variant: 'error'}))
    }

    const sendInvoice = (id) => {
        postData(`${id}/send_invoice/`, {})
            .then(r => enqueueSnackbar('Kiküldve!', {variant: 'success'}))
            .catch(() => enqueueSnackbar('Hiba!', {variant: 'error'}))
    }

    const createInvoice = async (id) => {
        return await postData(`${id}/create_invoice/`, {})
    }
    //
    // const getRooms = () => {
    //     getData(`get_for_apartment?apartment=${apartmentId}`)
    //         .then(r => setRooms(r))
    // }

    // const getRoom = (id) => {
    //     return rooms.find(r => r.id === id)
    // }
    //
    // useEffect(getRooms, [])

    const extraColActions = [{
        icon: <CheckIcon/>,
        onClick: (id) => acceptReservation(id),
        label: "Foglalás elfogadása",
        showInMenu: true,
        updateField: 'status',
        show: (params) => params.row['status'] === 'tmp'
    }, {
        icon: <AttachMoneyRoundedIcon/>,
        onClick: (id) => sendPaymentLink(id),
        label: "Fizetési link kiküldése",
        showInMenu: true,
        show: (params) => params.row['status'] === 'tmp'
    }, {
        icon: <AttachMoneyRoundedIcon/>,
        onClick: (id) => downloadInvoice(id),
        label: "Számla letöltése",
        showInMenu: true,
        show: (params) => params.row['invoice_id'] !== null && params.row['status'] === 'accepted'
    },{
        icon: <AttachMoneyRoundedIcon/>,
        onClick: (id) => sendInvoice(id),
        label: "Számla kiküldése",
        showInMenu: true,
        show: (params) => params.row['invoice_id'] !== null && params.row['status'] === 'accepted'
    }, {
        icon: <AttachMoneyRoundedIcon/>,
        onClick: (id) => createInvoice(id),
        label: "Számla elkészítése",
        showInMenu: true,
        updateField: 'invoice_id',
        show: (params) => params.row['invoice_id'] === null && params.row['status'] === 'accepted'
    }]
    //
    // const extraAlertDialogs = [{
    //     onNo: () => setAcceptReservationOpen(false),
    //     onYes: () => {
    //         setAcceptReservationOpen(false)
    //         acceptReservation(current)
    //     },
    //     open: acceptReservationOpen,
    //     title: `Biztosan elfogadja a foglalását?`,
    //     content: `Ez után fogja tudni kifizetni.`
    // }]

    return <ListView
        title="Foglalás"
        baseSlug="admin-reservations"
        pluralTitle="Foglalások"
        propColumns={columns}
        formRef={reservationForm}
        extraColActions={extraColActions}
        form={<ReservationData reference={reservationForm}/>}/>
}

export default Reservations