
const colorPalette = {
    '900': '#1a1a1a',
    '800': '#313131',
    '700': '#464646',
    'A700': '#5d5d5d',
    '600': '#777777',
    '500': '#838383',
    '400': '#8f8f8f',
    'A400': '#9a9a9a',
    '300': '#a2a2a2',
    '200': '#ababab',
    'A200': '#b6b6b6',
    '100': '#bdbdbd',
    'A100': '#cecece',
    '50': '#dcdcdc',
    '20': '#f1f1f1'
}

const components = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 10,
            }
        },
        variants: [
            {
                props: { variant: 'contained' },
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: colorPalette[600],
                    color: '#fff',
                    padding: '12px 16px',
                    gap: '10px',
                    '&:hover': {
                        background: colorPalette[400],
                    },
                    '&:active': {
                        background: colorPalette[500]
                    }
                },
            }, {
                props: { variant: 'outlined' },
                style: {
                    textTransform: 'none',
                    border: `2px solid ${colorPalette[600]}`,
                    background: 'transparent',
                    color: colorPalette[600],
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: colorPalette[50],
                        border: `2px solid ${colorPalette[600]}`,
                    },
                    '&:active': {
                        background: colorPalette[100],
                        border: `2px solid ${colorPalette[600]}`,
                    }
                },
            }, {
                props: { variant: 'text' },
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: 'transparent',
                    color: colorPalette[600],
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: colorPalette[50],
                    },
                    '&:active': {
                        background: colorPalette[100],
                    }
                },
            },
        ],
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 10,
                backgroundColor: colorPalette[20],
            }
        }
    },
    MuiInput: {
        styleOverrides: {
            root: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
                '&:hover': {
                    content: 'none',
                }
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                border: '1px solid #F3F3F1',
                background: '#fff',
                borderRadius: '16px'
            },
            cell: {
                border: 'none'
            },
        }
    }
}

export {components}