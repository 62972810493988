import {Button, NativeSelect, Stack, TextField, Typography} from "@mui/material";
import {useContext, useState} from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ConfigContext from "../../context/ConfigContext";


const ApartmentData = ({reference, obj}) => {
    const choices = [{days: 30, name: '1 hónap'},{days: 60, name: '2 hónap'},{days: 180, name: '6 hónap'}]
    const [maxReservationDate, setMaxReservationDate] = useState(obj?.max_reservation_date || 30)
    const [image, setImage] = useState(obj?.image || '')
    const {baseUrl} = useContext(ConfigContext)

    const getPic = () => {
        if (image instanceof File) return URL.createObjectURL(image)
        if (image?.startsWith('http')) return image
        if (image) return baseUrl + image;
        else return ''
    }
    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Név</Typography>
                <TextField variant='standard' id="name" name="name" defaultValue={obj?.name || ''}/>
                <Typography variant='caption'>Férőhelyek száma</Typography>
                <TextField variant='standard' id="capacity" name="capacity" defaultValue={obj?.capacity || ''}/>
                <Typography variant='caption'>Foglalások elfogadása eddig (napok száma)</Typography>
                <TextField type='number' variant='standard' id="max_reservation_date" name="max_reservation_date" defaultValue={obj?.max_reservation_date || ''}/>
                {/*<NativeSelect*/}
                {/*    inputProps={{name: 'max_reservation_date', id: 'controlled-max_reservation_date',}}*/}
                {/*    onChange={event => setMaxReservationDate(event.target.value)}*/}
                {/*    value={maxReservationDate}*/}
                {/*>*/}
                {/*    {choices.map((a, i) => <option key={`choice_${i}`} value={a?.days}>{a?.name}</option>)}*/}
                {/*</NativeSelect>*/}
                <Typography variant='caption'>Ár</Typography>
                <TextField variant='standard' id="price" name="price" defaultValue={obj?.price || ''}/>
                <Typography variant='caption'>Leírás</Typography>
                <TextField multiline rows={5} variant='standard' id="description" name="description" defaultValue={obj?.description || ''}/>
                <Typography variant='caption'>Kép</Typography>
                <img src={getPic(image)} style={{width: '30%', borderRadius: 10}}/>
                <Button sx={{padding: '16px'}} component='label' variant='outlined'
                        endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése
                    <input type="file"
                           onChange={event => setImage(event.target.files[0])}
                           hidden
                           name='image'
                           id='image'
                           accept='image/*'/>
                </Button>
            </Stack>

        </form>
    </>;
}

export default ApartmentData