import {Backdrop, CircularProgress, useTheme} from "@mui/material";
import dinoLogo from "../assets/logos/BeLa_wood_logo_black.png"

const Loading = ({isLoading}) => {
    const t = useTheme()
    return <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1, background: 'white'}}
        open={isLoading}
    >
        <img src={dinoLogo} style={{maxWidth: '10%', position: 'absolute', top: '30%', left: '50%', transform: "translateX(-50%)"}} alt=""/>
        <CircularProgress style={{color: t.palette.primary[700]}} />
    </Backdrop>
}

export default Loading