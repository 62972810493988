import CardView from "../../components/Views/CardView";
import {useContext, useRef, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ImageGalleryData from "./ImageGalleryData";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    return <Box className='white-card' sx={{width: '100%', background: `url(${obj.image})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color='#fff' variant='headline2'>{obj?.apartment_name} - ({obj?.order})</Typography>
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="contained" onClick={deleteHandler}>Törlés</Button>
        </Stack>
    </Box>
}


const ImageGallery = () => {


    const infoForm = useRef(null)
    return <CardView
        title='Kép'
        pluralTitle='Galéria'
        baseSlug='admin-image-gallery'
        formRef={infoForm}
        form={<ImageGalleryData reference={infoForm}/>}
        PropCard={PropCard}
        isImageInForm={true}
    />

}

export default ImageGallery