import {
    Stack,
    TextField,
    NativeSelect,
    Typography,
    Button,
} from "@mui/material";
import {useContext, useState} from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ApartmentContext from "../../context/ApartmentContext";
import ConfigContext from "../../context/ConfigContext";


const InfoData = ({reference, obj}) => {
    const [image, setImage] = useState(obj?.image || '')
    const [apartment, setApartment] = useState(obj?.apartment || 1)
    const {apartments} = useContext(ApartmentContext)
    const {baseUrl} = useContext(ConfigContext)

    const getPic = () => {
        if (image instanceof File) return URL.createObjectURL(image)
        if (image?.startsWith('http')) return image
        if (image) return baseUrl + image;
        else return ''
    }

    return <>

        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Ház</Typography>
                <NativeSelect
                    inputProps={{name: 'apartment', id: 'controlled-apartment',}}
                    onChange={event => setApartment(event.target.value)}
                    value={apartment}
                >
                    {apartments.map((a, i) => <option key={`apartment_${i}`} value={a?.id}>{a?.name}</option>)}
                </NativeSelect>
                {/*<Typography variant='caption'>Címsor</Typography>*/}
                {/*<TextField variant='standard' id="heading" name="heading" defaultValue={obj?.heading || ''}/>*/}
                <Typography variant='caption'>Leírás</Typography>
                <TextField variant='standard' multiline rows={7} id="text" name="text" defaultValue={obj?.text || ''}/>

                <Typography variant='caption'>Kép</Typography>
                <img src={getPic(image)} style={{width: '30%', borderRadius: 10}}/>
                <Button sx={{padding: '16px'}} component='label' variant='outlined'
                        endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése
                    <input type="file"
                           onChange={event => setImage(event.target.files[0])}
                           hidden
                           name='image'
                           id='image'
                           accept='image/*'/>
                </Button>
            </Stack>

        </form>
    </>;
}

export default InfoData