import CardView from "../../components/Views/CardView";
import {useRef} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import InfoData from "./InfoData";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    const theme = useTheme()


    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color={theme.palette.primary.main} variant='headline2'>{obj?.apartment_name}</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <img height='40px' width='40px' src={obj?.image} alt={obj?.name}/>
                <Typography variant='body2'>- {obj?.text}</Typography>
            </Stack>
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
        </Stack>
    </Box>
}


const Infos = () => {


    const infoForm = useRef(null)
    return <CardView
        title='Infó'
        pluralTitle='Infók'
        baseSlug='admin-infos'
        formRef={infoForm}
        form={<InfoData reference={infoForm}/>}
        PropCard={PropCard}
    />

}

export default Infos