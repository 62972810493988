import AuthBase from "../AuthBase";
import {Avatar, Box, Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Doughnut, Bar} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import DinoDataGrid from "../../components/DataGrid";
import AuthContext from "../../context/AuthContext";


const Dashboard = () => {
    const theme = useTheme()
    const [serviceStats, setServiceStats] = useState({})
    const [reservationStats, setReservationStats] = useState([])


    const {getData: fetchAdminReservations} = useCallDataApi('admin-reservations')
    const [loading, setLoading] = useState(true)
    const {user} = useContext(AuthContext)

    useEffect(() => {
        document.title = 'Vezérlőpult'
        fetchAdminReservations('get_yearly_stats/')
            .then(r => setReservationStats(r?.stat))
            .finally(() => setLoading(false))
    }, [])


    ChartJS.register(ArcElement, Tooltip, Legend);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
    );


    const data = {
        labels: serviceStats?.s,
        datasets: [
            {
                label: 'Foglalások száma',
                data: serviceStats?.r,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
    };
    const labels = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];

    const data2 = {
        labels,
        datasets: [
            {
                label: 'Bevétel (Ft.)',
                data: reservationStats,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: 'Dataset 2',
            //     data: labels.map(() => 200),
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
        ],
    };


    return <AuthBase label={`Üdv, ${user.username}!`}>
        <div className='main'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box className='white-card'>
                        <Typography variant='headline6'>Foglalások</Typography>
                        <Typography variant='body1' color='#536B74' marginBottom={3}>Bevételek havi bontásban.</Typography>
                        <Bar options={options} data={data2} />
                    </Box>
                </Grid>
            </Grid>
        </div>

        <Loading isLoading={loading} />
    </AuthBase>
}

export default Dashboard