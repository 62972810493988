import * as React from 'react';
import {
    DataGridPro,
    LicenseInfo,
    huHU,
    GridToolbar,
} from '@mui/x-data-grid-pro';
import Box from "@mui/material/Box";


const DinoDataGrid = ({rows, columns}) => {
    LicenseInfo.setLicenseKey(
        process.env.REACT_APP_MUI_LICENSE_KEY,
    );

    return <Box sx={{height: '70vh', width: '100%'}}>
        <DataGridPro
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick={true}
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />
    </Box>
}

export default DinoDataGrid