import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./typography";
import {components} from "./componentDef";

//
// const shitBrown = {
//     '900': '#0c0a08',
//     '800': '#312921',
//     '700': '#493d31',
//     'A700': '#625241',
//     '600': '#7a6652',
//     '500': '#87705a',
//     '400': '#937a62',
//     'A400': '#9d856c',
//     '300': '#a58f78',
//     '200': '#ad9985',
//     'A200': '#b6a391',
//     '100': '#bead9d',
//     'A100': '#cec2b6',
//     '50': '#ded6ce',
//     '20': '#efebe7'
// }

const colorPalette = {
    '900': '#1a1a1a',
    '800': '#313131',
    '700': '#464646',
    'A700': '#5d5d5d',
    '600': '#777777',
    '500': '#838383',
    '400': '#8f8f8f',
    'A400': '#9a9a9a',
    '300': '#a2a2a2',
    '200': '#ababab',
    'A200': '#b6b6b6',
    '100': '#bdbdbd',
    'A100': '#cecece',
    '50': '#dcdcdc',
    '20': '#f1f1f1'
}


const greenTheme = createTheme({
        components: components,
        name: 'green',
        palette: {
            primary: colorPalette,
            secondary: colorPalette,
        },
        typography: typography
    },
    huHU
)

export default greenTheme
