import useCallDataApi from "../../hooks/data";
import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import {useSnackbar} from "notistack";
import {getFormData, getFormDataNative, getFormDataWithImage} from "../../utils/FormUtils";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AuthBase from "../../pages/AuthBase";
import Loading from "../Loading";
import DinoDialog from "../Dialog";
import Button from "@mui/material/Button";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {DeleteForeverRounded} from "@mui/icons-material";


const CardView = ({
                      baseSlug,
                      getSlug = '',
                      title,
                      pluralTitle,
                      formRef,
                      imageFiledName = null,
                      form,
                      propColumns = [],
                      extraColActions = [],
                      extraAlertDialogs = [],
                      extraButtons = [],
                      PropCard,
                      isImageInForm = null,
                  }) => {
    const {getData, updateData, deleteData, formDataCreateData, formDataUpdateData} = useCallDataApi(baseSlug);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(1)
    const [newPopupOpen, setNewPopupOpen] = useState(false)
    const [editPopupOpen, setEditPopupOpen] = useState(false)
    const [deletePopupOpen, setDeletePopupOpen] = useState(false)
    const {enqueueSnackbar} = useSnackbar();

    const generalGetList = () => {
        getData(getSlug).then(d => {
            setLoading(false)
            setData(d)
        })
    }

    const generalNew = (obj) => {
        setLoading(true)
        formDataCreateData(obj).then(
            newObj => {
                if (newObj) {
                    setData([...data, newObj])
                    enqueueSnackbar('Elmentve!', {variant: 'success'})
                }
            }
        ).finally(() => setLoading(false))
    }

    const generalEdit = (obj) => {
        setLoading(true)
        formDataUpdateData(current, obj).then(
            editedObj => {
                const editedDataList = data.map(d => {
                    if (current === d.id) return {...d, ...editedObj}
                    return d;
                });
                setData(editedDataList)
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            }
        ).finally(() => setLoading(false))
    }

    // const addImageToData = (obj) => {
    //     formDataUpdateData(current, obj).then(editedObj => {
    //         const editedDataList = data.map(d => {
    //             if (current === d.id) return {...d, ...editedObj}
    //             return d;
    //         });
    //         setData(editedDataList)
    //         enqueueSnackbar('Szerkesztve!', {variant: 'success'})
    //     })
    // }

    const generalDelete = () => {
        deleteData(current).then(() => {
            setData(data.filter(d => (d.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const handleNew = () => {
        let formData
        if (isImageInForm) formData = getFormDataNative(formRef.current)
        else formData = getFormData(formRef.current)
        generalNew(formData)
    }

    const handleEdit = () => {
        let formData
        if (isImageInForm) formData = getFormDataNative(formRef.current)
        else formData = getFormData(formRef.current)
        generalEdit(formData)
    }

    // const handleAddImageToData = (img) => {
    //     addImageToData(getFormDataWithImage(formRef.current, img))
    // }

    const getObj = (id) => {
        return data.find(service => service.id === id)
    }

    useEffect(() => {
        generalGetList()
        document.title = pluralTitle
    }, [])

    const buttons = [{
        name: `Új ${title} hozzáadása`,
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => setNewPopupOpen(true)
        }
    }, ...extraButtons];

    const editForm = React.Children.map(form, i => {
        const obj = getObj(current)
        if (React.isValidElement(form)) {
            return React.cloneElement(form, {obj});
        }
        return form;
    });

    return <AuthBase label={pluralTitle} buttons={buttons} filterButtonsMobile={buttons}>
        <div className='main'>
            <Grid container spacing={2} style={{marginBottom: '20px'}}>
                {data.map(d => (
                    <Grid item xs={12} md={6} key={`card_${d.id}`}>
                        <PropCard obj={d} deleteHandler={() => {
                            setCurrent(d.id)
                            setDeletePopupOpen(true)
                        }} editHandler={() => {
                            setCurrent(d.id)
                            setEditPopupOpen(true)
                        }}/>
                    </Grid>
                ))}
            </Grid>

            <DinoDialog
                title={`Új ${title}`}
                subtitle={`Új ${title} hozzáadása`}
                open={newPopupOpen}
                handleClose={() => (setNewPopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}}
                            onClick={() => setNewPopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setNewPopupOpen(false)
                        handleNew()
                    }} endIcon={<AddCircleOutlineRoundedIcon/>}>Hozzáadás</Button>
                </>}
            >
                {form}
            </DinoDialog>

            <DinoDialog
                title={`${title} szerkesztése`}
                subtitle={`${title} szerkesztése`}
                open={editPopupOpen}
                handleClose={() => (setEditPopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}}
                            onClick={() => setEditPopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setEditPopupOpen(false)
                        handleEdit()
                    }} endIcon={<EditRoundedIcon/>}>Szerkesztés</Button>
                </>}
            >
                {editForm}
            </DinoDialog>


            <DinoDialog
                title='Biztosan törli?'
                subtitle='Nem vonható vissza.'
                open={deletePopupOpen}
                handleClose={() => (setDeletePopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}}
                            onClick={() => setDeletePopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setDeletePopupOpen(false)
                        generalDelete(current)
                    }} endIcon={<DeleteForeverRounded/>}>Törlés</Button>
                </>}
            />

            {/*{extraAlertDialogs.map((d, i) =>*/}
            {/*    <AlertDialog*/}
            {/*        key={`alert_${i}`}*/}
            {/*        onNo={d.onNo}*/}
            {/*        onYes={d.onYes}*/}
            {/*        open={d.open}*/}
            {/*        title={d.title}*/}
            {/*        content={d.content}/>)}*/}
        </div>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default CardView