import MiniDrawer from "../AdminDrawer";
import {GridActionsCellItem} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import {getFormData} from "../../utils/FormUtils";
import {useSnackbar} from "notistack";
import AuthBase from "../../pages/AuthBase";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DinoDialog from "../Dialog";
import Button from "@mui/material/Button";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {DeleteForeverRounded} from "@mui/icons-material";
import Loading from "../Loading";
import DinoDataGrid from "../DataGrid";


const ListView = ({
                      baseSlug,
                      getSlug = '',
                      title,
                      pluralTitle,
                      formRef,
                      form,
                      propColumns = [],
                      extraColActions = [],
                      extraAlertDialogs = [],
                      extraButtons = [],
                      extraScrollDialogs = []
                  }) => {
    const [current, setCurrent] = useState(1)
    const [data, setData] = useState([])
    const [newPopupOpen, setNewPopupOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editPopupOpen, setEditPopupOpen] = useState(false)
    const [deletePopupOpen, setDeletePopupOpen] = useState(false)
    const {getData, createData, updateData, deleteData} = useCallDataApi(baseSlug);
    const {enqueueSnackbar} = useSnackbar();


    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        setDeletePopupOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        setEditPopupOpen(true)
                    }}
                    label="Szerkesztés"
                    showInMenu={true}
                />,
                ...extraColActions.filter(action => action.show(params)).map((action, i) =>
                    <GridActionsCellItem
                        key={`action_${i}`}
                        icon={action.icon}
                        onClick={async () => {
                            const id = params.row['id']
                            if (Boolean(action?.updateField)) {
                                const resp = await action.onClick(id)
                                updateValue(id, action.updateField, resp[action.updateField])
                            } else {
                                action.onClick(id)
                            }
                        }}
                        label={action.label}
                        showInMenu={action.showInMenu}
                    />)
            ],
        },
        ...propColumns
    ];

    document.title = pluralTitle

    const buttons = [{
        name: `Új ${title} hozzáadása`,
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => setNewPopupOpen(true)
        }
    }, ...extraButtons];

    const updateValue = (id, fieldName, value) => {
        const obj = getObj(id)
        // const restOfData = data.filter(r => r.id !== id)
        const editedData = data.map(d => {
            if (d?.id === id) return {...d, [fieldName]: value}
            else return d
        })
        // console.log('new', {...obj, [fieldName]: value})
        // setData([...restOfData, {...obj, [fieldName]: value}])
        setData([...editedData])
        enqueueSnackbar('Szerkesztve!', {variant: 'success'})
    }

    const generalGetList = () => {
        getData(getSlug).then(r => {
            setData(r)
            setLoading(false)
        })
    }

    const generalNew = (o) => {
        createData(o).then(newObj => {
            if (newObj) {
                setData(r => [...data, newObj])
                enqueueSnackbar('Elmentve!', {variant: 'success'})
            }
        })
    }

    const generalEdit = (o) => {
        updateData(current + '/', o).then(editedObj => {
            if (editedObj) {
                const restOfData = data.filter(r => r.id !== current)
                setData([...restOfData, editedObj])
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            }
        })
    }

    const generalDelete = (id) => {
        deleteData(id).then(() => {
            const restOfData = data.filter(r => r.id !== current)
            setData(restOfData)
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const handleNew = () => {
        const formData = getFormData(formRef.current)
        generalNew(formData)
    }

    const handleEdit = () => {
        const formData = getFormData(formRef.current)
        generalEdit(formData)
    }

    const getObj = (id) => {
        return data.find(r => r.id === id)
    }

    useEffect(() => {
        generalGetList()
    }, [])

    const onRowClick = () => {
        console.log('row cilcked')
    }

    const editForm = React.Children.map(form, i => {
        const obj = getObj(current)
        if (React.isValidElement(form)) {
            return React.cloneElement(form, {obj});
        }
        return form;
    });

    return <AuthBase label={pluralTitle} buttons={buttons} filterButtonsMobile={buttons}>
        <div className='main'>
            <DinoDataGrid loading={loading} rows={data} columns={columns} onRowClick={onRowClick}/>

            <DinoDialog
                title={`Új ${title}`}
                subtitle={`Új ${title} hozzáadása`}
                open={newPopupOpen}
                handleClose={() => (setNewPopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}} onClick={() => setNewPopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setNewPopupOpen(false)
                        handleNew()
                    }} endIcon={<AddCircleOutlineRoundedIcon/>}>Hozzáadás</Button>
                </>}
            >
                {form}
            </DinoDialog>

            <DinoDialog
                title={`${title} szerkesztése`}
                subtitle={`${title} szerkesztése`}
                open={editPopupOpen}
                handleClose={() => (setEditPopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}} onClick={() => setEditPopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setEditPopupOpen(false)
                        handleEdit()
                    }} endIcon={<EditRoundedIcon/>}>Szerkesztés</Button>
                </>}
            >
                {editForm}
            </DinoDialog>


            <DinoDialog
                title='Biztosan törli?'
                subtitle='Nem vonható vissza.'
                open={deletePopupOpen}
                handleClose={() => (setDeletePopupOpen(false))}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}} onClick={() => setDeletePopupOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setDeletePopupOpen(false)
                        generalDelete(current)
                    }} endIcon={<DeleteForeverRounded/>}>Törlés</Button>
                </>}
            />

        </div>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default ListView