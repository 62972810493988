import CardView from "../../components/Views/CardView";
import {useEffect, useRef, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AboutUsData from "./AboutUsData";
import useCallDataApi from "../../hooks/data";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    const theme = useTheme()

    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color={theme.palette.primary.main} variant='headline2'>{obj?.heading} - {obj?.apartment_name}</Typography>
            <Typography variant='body2'>{obj?.text}</Typography>
            <img src={obj?.image} alt=""/>
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
        </Stack>
    </Box>
}


const AboutUs = () => {
    const aboutUsForm = useRef(null)
    return <CardView
        title='Rólunk'
        pluralTitle='Rólunk'
        baseSlug='admin-about-us'
        formRef={aboutUsForm}
        form={<AboutUsData reference={aboutUsForm}/>}
        PropCard={PropCard}
    />

}

export default AboutUs