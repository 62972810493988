import CardView from "../../components/Views/CardView";
import {useContext, useEffect, useRef, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import thousandSeparator from "../../utils/numberUtils";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FeaturedPeriodData from "./FeaturedPeriodData";
import ApartmentContext from "../../context/ApartmentContext";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    const theme = useTheme()
    const {getApartment} = useContext(ApartmentContext)

    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color={theme.palette.primary.main} variant='headline2'>{obj?.name}</Typography>

            <Typography variant='caption'>Időtartam: {obj.start_date} - {obj.end_date}</Typography>
            <Typography variant='caption'>Minimum foglalható napok száma: {obj.min_days}</Typography>
            <Typography variant='caption'>Ár: {thousandSeparator(obj.price)} Ft</Typography>
            <Typography variant='caption'>Ház: {getApartment(obj?.apartment)?.name}</Typography>
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
        </Stack>
    </Box>
}


const Featured = () => {


    const periodForm = useRef(null)
    return <CardView
        title='Kiemelt időszak'
        pluralTitle='Kiemelt időszakok'
        baseSlug='admin-periods'
        formRef={periodForm}
        form={<FeaturedPeriodData reference={periodForm}/>}
        PropCard={PropCard}
    />

}

export default Featured