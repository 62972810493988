import {createContext, useContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import AuthContext from "./AuthContext";

const ApartmentContext = createContext();

export default ApartmentContext;

export const ApartmentProvider = ({ children }) => {
    const {user} = useContext(AuthContext)
    const {getData: fetchApartments, formDataUpdateData: formDataUpdateApartment} = useCallDataApi('admin-apartments')
    const [apartments, setApartments] = useState([])

    const getApartment = (id) => apartments.find(a => a.id === id)

    useEffect(() => {
        if (user) fetchApartments().then(a => setApartments(a))
    }, [user])

    const contextData = {
        getApartment,
        apartments,
        setApartments,
        formDataUpdateApartment
    }

    return <ApartmentContext.Provider value={contextData}>{children}</ApartmentContext.Provider>
}