import {Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import greenLogo from "../assets/logos/BeLa_wood_logo_black.png"
import {HashLink} from "react-router-hash-link";


const DinoFooter = () => {
    const theme = useTheme()

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer">
        <div className="dino-footer-content">
            <Grid container spacing={12}>
                <Grid item xs={12} lg={3}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Kontakt</Typography>
                        <Typography variant="buttonNormal" style={primaryColor}>3394 Egerszalók, Galamb utca 29</Typography>
                        <Typography variant="button" style={primaryColor}>+36 30 864 2606</Typography>
                        <Typography variant="button" style={primaryColor}>belawoodinfo@gmail.hu</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {/*<SocialButton onClick={() => window.open('/', '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>*/}
                        {/*<SocialButton onClick={() => window.open('/', '_blank')} inverted socialIcon={<InstagramIcon/>}/>*/}
                        {/*<SocialButton onClick={() => window.open('/', '_blank')} inverted socialIcon={<TwitterIcon/>}/>*/}
                        {/*<SocialButton onClick={() => window.open('/', '_blank')} inverted socialIcon={<YouTubeIcon/>}/>*/}
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Szekciók</Typography>
                        <HashLink to="/" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Kezdőlap</Typography>
                        </HashLink>
                        <HashLink to="/login" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Bejelentkezés</Typography>
                        </HashLink>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={2}/>
                <Grid item xs={12} lg={3}>
                    <div className="dino-footer-container__own-logo">
                        <img src={greenLogo} alt="Dinobooking logo" width="100px"/>
                    </div>
                </Grid>
            </Grid>

        </div>
    </div>

}

export default DinoFooter