import CardView from "../../components/Views/CardView";
import CouponCodeData from "./CouponCodeData";
import {useRef} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import thousandSeparator from "../../utils/numberUtils";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {enqueueSnackbar} from "notistack";

export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    const theme = useTheme()
    let discountText = ''
    if (obj?.percent) discountText = `${obj.percent} % kedvezmény foglalásonként.`
    else discountText = `-${thousandSeparator(obj.minus)} Ft foglalásonként.`

    const today = new Date()
    const validity = new Date(obj.valid_thru)
    const overDue = today > validity

    function copyCode(obj) {
        navigator.clipboard.writeText(obj.code);
        enqueueSnackbar('Vágólapra másolva!')
    }

    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color={theme.palette.primary.main} sx={overDue ? {textDecoration: 'line-through'} : {}}
                        variant='headline2'>Kód: "{obj?.code}"</Typography>

            <Typography variant='caption'>{discountText}</Typography>
            {overDue ? <Typography variant='caption'>Lejárt</Typography> :
                <Typography variant='caption'>Érvényes eddig: {obj.valid_thru}</Typography>}
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
            <Button startIcon={<ContentCopyRoundedIcon/>} variant="outlined" onClick={() => copyCode(obj)}>Másolás</Button>
        </Stack>
    </Box>
}

const CouponCodes = () => {

    // const filterButtons = [
    //     {
    //         name: 'Aktív foglalások',
    //         props: {
    //             variant: reservationStatus === 'accepted' ? 'contained' : 'text',
    //             onClick: () => {
    //                 setReservationStatus('accepted')
    //             }
    //         }
    //     },
    //     {
    //         name: 'Korábbi foglalások',
    //         props: {
    //             variant: reservationStatus === 'accepted' ? 'text' : 'contained',
    //             onClick: () => {
    //                 setReservationStatus('rejected')
    //             }
    //         }
    //     }
    // ]


    const couponForm = useRef(null)
    return <CardView
        title='Kuponkód'
        pluralTitle='Kuponkódok'
        baseSlug='admin-coupons'
        formRef={couponForm}
        form={<CouponCodeData reference={couponForm}/>}
        PropCard={PropCard}
    />

}

export default CouponCodes