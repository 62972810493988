import sadDino from '../assets/sad.gif'
import Typography from "@mui/material/Typography";
import Base from "./authentication/Base";
import {useTheme} from "@mui/material/styles";

const NotFound = () => {
    const theme = useTheme()
    document.title = '404'

    return <Base>
        <div className="not-found-container">
            <Typography variant="headline2" style={{color: theme.palette.primary[600]}} gutterBottom>404, az oldal nem található!</Typography>
            <img src={sadDino} alt="sad" style={{width: '50%'}}/>
        </div>
    </Base>

}

export default NotFound