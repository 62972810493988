import './App.scss';
import {ThemeProvider} from "@mui/material";
import {ConfigProvider} from "./context/ConfigContext";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";
import greenTheme from "./components/themes/GreenTheme";
import Login from "./pages/authentication/Login";
import Dashboard from "./pages/home/Dashboard";
import CouponCodes from "./pages/couponCodes/CouponCodes";
import Featured from "./pages/FeaturedPeriods/FeaturedPeriods";
import Reservations from "./pages/reservations/Reservations";
import AboutUs from "./pages/aboutUs/AboutUs";
import Infos from "./pages/infos/Infos";
import ImageGallery from "./pages/imageGallery/ImageGallery";
import NotFound from "./pages/404";
import Settings from "./pages/settings/Settings";
import {ApartmentProvider} from "./context/ApartmentContext";

function App() {
    return <ThemeProvider theme={greenTheme}>
        <ConfigProvider>
            <Router>
                <AuthProvider>
                    <ApartmentProvider>
                        <Switch>
                            <PrivateRoute path="/" manager component={Dashboard} exact/>
                            <PrivateRoute path="/coupons" manager component={CouponCodes} exact/>
                            <PrivateRoute path="/featured" manager component={Featured} exact/>
                            <PrivateRoute path="/reservations" manager component={Reservations} exact/>
                            <PrivateRoute path="/about-us" manager component={AboutUs} exact/>
                            <PrivateRoute path="/infos" manager component={Infos} exact/>
                            <PrivateRoute path="/gallery" manager component={ImageGallery} exact/>
                            <PrivateRoute path="/settings" manager component={Settings} exact/>


                            <Route path="/login" component={Login} exact/>

                            <Route component={NotFound}/>
                        </Switch>
                    </ApartmentProvider>
                </AuthProvider>
            </Router>
        </ConfigProvider>
    </ThemeProvider>
}

export default App;
